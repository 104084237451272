import React from 'react';

const isProductionEnvironment = process.env.NODE_ENV === 'production';

export const EnvironmentIndicator = () => {
  const developmentIndicator = (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#d9534f',
        bottom: 0,
        color: 'white',
        display: 'flex',
        fontSize: 16,
        fontWeight: 800,
        height: 50,
        justifyContent: 'center',
        right: 0,
        pointerEvents: 'none',
        position: 'fixed',
        transform: 'rotate(-35deg)',
        transformOrigin: '50% -150%',
        width: 250,
        zIndex: 1000,
      }}
    >
      Homologação
    </div>
  );

  return <>{!isProductionEnvironment && developmentIndicator}</>;
};

export default EnvironmentIndicator;
